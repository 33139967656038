<!-- =========header section========= -->

<section class="header">
    <div class="top">
        <div class="govt_logo">
            <div style="width: fit-content;margin: auto;text-align: center;">
                <img src="assets/images/indianLogo.jpg">
            </div>
            <h4>
                <div>GOVERNMENT OF TRIPURA</div>
                <div>JOINT RECRUITMENT BOARD TRIPURA (JRBT)</div>
                <div>DIRECTORATE OF EMPLOYMENT SERVICES & MANPOWER PLANNING</div>
                <div>AGARTALA, TRIPURA</div>

            </h4>
            <div>
                <button class="btn btn-primary ml-3" style="float: right;font-size: 14px;background-color: #1C85AC;border: none;" routerLink='/callletter'>Call Letter Download</button>
                <button class="btn btn-primary" style="float: right;font-size: 14px;background-color: #1C85AC;border: none;"  routerLink='/admitcard'>Admit Card Download </button>
                <!-- <a routerLink='/callletter'
                    style="float: right;font-size: 17px;font-weight:700;cursor:pointer;text-decoration: underline;">Call
                    Letter Download</a>
                <span style="float: right;font-size: 17px;font-weight:700;"> / </span>
                <a routerLink='/admitcard'
                    style="float: right;font-size: 17px;font-weight:700;cursor:pointer;text-decoration: underline;">Admit
                    Card Download </a> -->
            </div>
        </div>
    </div>
    <div class="flex_grow">

        <div class="cm_pto">
            <!-- <img src="assets/images/Tripura-Chief-Minister.jpg"> -->
        </div>
    </div>
</section>

<!-- =========header section end========= -->
<!-- =========body section========= -->
<section class="body">
    <div class="main_cnt">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="text_marqee">
                        Attention
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 govt_logo left_img h-100">
                    <img src="../../assets/images/loginImg2.jpg" class="mt-5" width="100%" height="230px" alt="">
                    <div>
                        <ul style="margin: 0;padding-bottom: 0;">

                            <!-- 07-09-24 -->

                            <li class="discloser" >
                                <a download="Notice regarding publication of final merit list of MTS (Gr-D) posts (English).pdf" target="_blank"
                                    href="/assets/07-09-24/Notice regarding publication of final merit list of MTS (Gr-D) posts (English).pdf" class="newContent">
                                    Notice (In English) for publication of final merit list for recruitment to the posts of Multi Tasking Staff(Gr.-D) as recommended by Joint Recruitment Board Tripura (JRBT) based on Merit of the candidates.
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Notice regarding publication of final merit list of MTS (Gr-D) posts (Bengali).pdf" target="_blank"
                                    href="/assets/07-09-24/Notice regarding publication of final merit list of MTS (Gr-D) posts (Bengali).pdf" class="newContent">
                                    Notice (In Bengali) for publication of final merit list for recruitment to the posts of Multi Tasking Staff(Gr.-D) as recommended by Joint Recruitment Board Tripura (JRBT) based on Merit of the candidates.
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Final Merit List of MTS(Gr.-D) Posts Recommended by JRBT.pdf" target="_blank"
                                    href="/assets/07-09-24/Final Merit List of MTS(Gr.-D) Posts Recommended by JRBT.pdf" class="newContent">
                                    Final merit list for recruitment to the posts of Multi Tasking Staff(Gr.-D) as recommended by Joint Recruitment Board, Tripura (JRBT) based on merit of the candidates.
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li>
                            <!-- 07-09-24 -->

                           <!-- 05-09-24 -->
                            <li class="discloser" >
                                <a download="Notice regarding MTS (Gr D) posts (English).pdf" target="_blank"
                                    href="/assets/05-09-24/Notice regarding MTS (Gr D) posts (English).pdf" class="newContent">
                                    Notice regarding MTS (Gr D) posts (English)
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Notice regarding MTS (Gr D) posts (Bengali).pdf" target="_blank"
                                    href="/assets/05-09-24/Notice regarding MTS (Gr D) posts (Bengali).pdf" class="newContent">
                                    Notice regarding MTS (Gr D) posts (Bengali)
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li>
                            <!-- 05-09-24 -->

                            <li class="discloser">
                                <b>Provisional Admission Certificates (for Interview) of Multi Tasking Staff (Group -D) post have been uploaded & made live for downloading of the same by the concerned candidates</b>
                            </li>

                            <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice English for MTS (Gr.-D).pdf" class="newContent">
                                    Notice regarding interview for the post of Multi Tasking Staff (Gr.-D) under JRBT (English)
                    
                                </a>
                            </li>
                            <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice Bangla for MTS (Gr.-D).pdf" class="newContent">
                                    Notice regarding interview for the post of Multi Tasking Staff (Gr.-D) under JRBT (Bengali)
                
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice English for MTS (Gr.-D).pdf" class="newContent">
                                    Notice regarding Document Verification and Interview for Multi Tasking Staff (Gr. - D) English
                
                                </a>
                            </li>
                            <li class="discloser" >
                                <a download="Notice Bangla for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice Bangla for MTS (Gr.-D).pdf" class="newContent">
                                    Notice regarding Document Verification and Interview for Multi Tasking Staff (Gr. - D) Bangla
                                    
                                </a>
                            </li>

                            <!-- <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (English).pdf" class="newContent">
                                    Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (English)
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (Bengali).pdf" class="newContent">
                                    Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (Bengali)
                                     - <span class="blink_me"> New</span>
                                </a>
                            </li> -->

                            <li class="discloser" >
                                <a download="summaryofwrittenexaminationforrecruitmentofGrCposts.pdf" target="_blank"
                                    href="/assets/summaryofwrittenexaminationforrecruitmentofGrCposts.pdf" class="newContent">
                                    Summary of Written Examination for recruitment of Group-C posts vide Advertisement No. 01/2020 dated 27th November, 2020 (Revision of Advertisement No. 01/2020 dated 12th February, 2021)
                                    
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Corrigendumdated30.09.2023.pdf" target="_blank"
                                    href="/assets/Corrigendumdated30.09.2023.pdf" class="newContent">
                                    Corrigendum of the notification dated 13/09/2023
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Corrigendum Dated.pdf" target="_blank"
                                    href="/assets/Corrigendumdated.pdf" class="newContent">
                                    Corrigendum of the notification dated 13/09/2023
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Notice (Bengali).pdf" target="_blank"
                                    href="/assets/Notice (Bengali).pdf" class="newContent">
                                    Notice (In Bengali) for publication of final merit list for recruitment to the posts of Group-C as recommended by Joint Recruitment Board Tripura (JRBT) based on Merit & Preference of the candidates
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Notice (English).pdf" target="_blank"
                                    href="/assets/Notice (English).pdf" class="newContent">
                                    Notice (In English) for publication of final merit list for recruitment to the posts of Group-C as recommended by Joint Recruitment Board Tripura (JRBT) based on Merit & Preference of the candidates
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Final Merit List of Group C.pdf" target="_blank"
                                    href="/assets/FinalmeritlistofGroup-Cposts1.pdf" class="newContent">
                                    Final merit list for recruitment to the posts of Group-C as recommended by Joint Recruitment Board Tripura (JRBT) based on Merit & Preference of the candidates
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Resuming Notice In Bengali.pdf" target="_blank"
                                    href="/assets/Notice in Bengali.pdf" class="newContent">
                                    Resuming Notice In Bengali
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Resuming notice in English.pdf" target="_blank"
                                    href="/assets/Notice in English.pdf" class="newContent">
                                    Resuming notice in English
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Interview Resuming Notice JRBT.pdf" target="_blank"
                                    href="/assets/Resume Notice.pdf" class="newContent">
                                    Interview Resuming Notice JRBT
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Notice for postponement of ongoing Document Verification & Interview of Gr-C post (Bengali).pdf" target="_blank"
                                    href="/assets/NoticeforpostponementofongoingDocumentVerification&InterviewofGr-Cpost(Bengali).pdf" class="newContent">
                                    Notice for postponement of ongoing Document Verification & Interview of Gr-C post (Bengali)
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Notice.pdf" target="_blank"
                                    href="/assets/Notice.pdf" class="newContent">
                                    Notice of postponement of Gr- C Interview on 02/01/2023
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="2022_12_26 5_43 PM Office Lens (1) Bengali.pdf" target="_blank"
                                    href="/assets/2022_12_26 5_43 PM Office Lens (1).pdf" class="newContent">
                                    Notice regarding interview for the posts of Group C under JRBT (Bengali)
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="2022_12_26 5_43 PM Office Lens.pdf" target="_blank"
                                    href="/assets/2022_12_26 5_43 PM Office Lens.pdf" class="newContent">
                                    Notice regarding interview for the posts of Group C under JRBT (English)
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Post Preference Format for Group-C Posts.pdf" target="_blank"
                                    href="/assets/Post Preference Format.pdf" class="newContent">
                                    Post Preference Format for Group-C Posts
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Notice For Interview.pdf" target="_blank"
                                    href="/assets/noticeforinterview.pdf" class="newContent">
                                    Notice For Interview
                                </a>
                            </li>
                            <li class="discloser">
                                <b>Provisional Admission Certificates (for Interview) of Group -C post have been uploaded &  made live for downloading of the same by the concerned candidates</b>
                                
                            </li>
                            <li class="discloser">
                                <a download=" Notice for publication of list of provisionally qualifiedand candidates for Interview for Group-C &MTS(Gr.-D) posts.pdf" target="_blank"
                                    href="/assets/NoticeforpublicationoflistofprovisionallyqualifiedcandidatesforInterviewforGroup-C&MTS(Gr.-D) posts.pdf" class="newContent">
                                    Notice for publication of list of provisionally qualifiedand candidates for Interview for Group-C &MTS(Gr.-D) posts
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Notice for publication of list of provisionally qualified candidates for Interview for Group-C&MTS(Gr.-D)posts.pdf" target="_blank"
                                    href="/assets/NoticeforpublicationoflistofprovisionallyqualifiedcandidatesforInterviewforGroup-C&MTS(Gr.-D)posts.pdf" class="newContent">
                                    Notice for publication of list of provisionally qualified candidates for Interview for Group-C&MTS(Gr.-D)posts
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" List of Provisionally Qualified Candidates for Interview for Group - C post.pdf" target="_blank"
                                    href="/assets/ListofProvisionallyQualifiedCandidatesforInterviewforGroup-Cpost.pdf" class="newContent">
                                    List of Provisionally Qualified Candidates for Interview for Group - C post
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" List of Provisionally Qualified Candidates for Interview for MTS ( Group - D)post.pdf" target="_blank"
                                    href="/assets/ListofProvisionallyQualifiedCandidatesforInterviewfor MTS( Group-D)post.pdf" class="newContent">
                                    List of Provisionally Qualified Candidates for Interview for MTS ( Group - D)post
                                </a>
                            </li>
                            <li class="discloser">
                                <a href="javascript:void(0)" class="newContent text-hard-dark">
                                    The list of provisionally qualified candidates for Interview in c/w written examinations for MTS(Gr-D) & Gr-C posts conducted by JRBT on 20/08/2021 & 22/08/2021 will be published and uploaded in this portal within this month; candidates are requested to watch this space.
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Reimbursement Of Challenge Fee of Answer Keys.pdf" target="_blank"
                                    href="/assets/ReimbursementofChallengeFeeofAnswerKeys.pdf" class="newContent">
                                    Reimbursement Of Challenge Fee of Answer Keys of JRBT
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Format for Reimbursement of Challenge Fee of Answer Keys of JRBT.pdf" target="_blank"
                                    href="/assets/FormatforReimbursementofChallengesFeeofAnswerKeysofJRBT06-Jan-2022 14-11-03.pdf" class="newContent">
                                    Format for Reimbursement of Challenge Fee of Answer Keys of JRBT
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Notice for Reimbursement of Challenge Fee of Answer Keys of JRBT.pdf" target="_blank"
                                    href="/assets/NoticeforReimbursementofChallengesFeeofAnswerKeys06-Jan-2022 14-14-30.pdf" class="newContent">
                                    Notice for Reimbursement of Challenge Fee of Answer Keys of JRBT
                                </a>
                            </li>

                            <li class="discloser">
                                <a download=" Corrigendum.pdf" target="_blank"
                                    href="/assets/Corrigendum.pdf" class="newContent">
                                    Corrigendum
                                </a>
                            </li>

                            <li class="discloser">
                                <a download=" Corrigendum for Gr-C(paper-I) Final Answer Key.pdf" target="_blank"
                                    href="/assets/Corrigendum for Gr-C(paper-I) Final Answer Key.pdf" class="newContent">
                                    Corrigendum for Gr-C(paper-I) Final Answer Key
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Corrigendum for MTS(Gr-D) Final Answer Key.pdf" target="_blank"
                                    href="/assets/Corrigendum for MTS(Gr-D) Final Answer Key.pdf" class="newContent">
                                    Corrigendum for MTS(Gr-D) Final Answer Key
                                </a>
                            </li>





                            <li class="discloser">
                                <a download=" Notice Regarding Final Answer Keys (English).pdf" target="_blank"
                                    href="/assets/Notice Regarding Final Answer Keys (English).pdf" class="newContent">
                                    Notice Regarding Final Answer Keys (English)
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Notice Regarding Final Answer Keys (Bengali).pdf" target="_blank"
                                    href="/assets/Notice Regarding Final Answer Keys (Bengali).pdf" class="newContent">
                                    Notice Regarding Final Answer Keys (Bengali)
                                </a>
                            </li>

                            <li class="discloser">
                                <a download=" Final Answer Key Group-C- Paper - I.pdf" target="_blank"
                                    href="/assets/answersheet/Final Answer Key Group-C- Paper - I.pdf" class="newContent">
                                    Final Answer Key Group-C- Paper - I
                            </a>
                            </li>
                            <li class="discloser">
                                <a download=" Final Answer Key Group-C- Paper - II.pdf" target="_blank"
                                    href="/assets/answersheet/Final Answer Key Group-C- Paper - II.pdf" class="newContent">
                                    Final Answer Key Group-C- Paper - II
                                </a>
                            </li>
                            <li class="discloser">
                                <a download=" Final Answer key MTS (Group-D).pdf" target="_blank"
                                    href="/assets/answersheet/Final Answer key MTS (Group-D).pdf" class="newContent">
                                    Final Answer key MTS (Group-D)
                                </a>
                            </li>



                            <li class="discloser">
                                <a download=" Answer key challenge for candidates from outside of Tripura.pdf" target="_blank"
                                    href="/assets/Answer key challenge for candidates from outside of Tripura.pdf" class="newContent">
                                    Answer key challenge for candidates from outside of Tripura
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Answer Key for Gr-C(Paper-I) written Examination.pdf" target="_blank"
                                    href="/assets/answersheet/Answer Key for Gr-C(Paper-I) written Examination.pdf" class="newContent">
                                    Answer Key for Group-C (Paper-I)
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Answer Key for Gr-C(Paper-II) Written Examination.pdf" target="_blank"
                                    href="/assets/answersheet/Answer Key for Gr-C(Paper-II) Written Examination.pdf" class="newContent">
                                    Answer Key for Group-C (Paper-II)
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Answer key of Gr-D.pdf" target="_blank"
                                    href="/assets/answersheet/Answer key of Gr-D.pdf" class="newContent">
                                    Answer key of Group-D
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="Answer Key Challange format Group-C.pdf" target="_blank"
                                    href="/assets/answersheet/Answer Key Challange format Group-C.pdf" class="newContent">
                                    Format for challenging Answer Key for (Group-C)
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="Format for challenging Answer Key for MTS(Gr-D) written Examination.pdf" target="_blank"
                                    href="/assets/answersheet/Format for challenging Answer Key for MTS(Gr-D) written Examination.pdf" class="newContent">
                                    Format for challenging Answer Key for (Group-D)
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="JRBT Notification in Bengali.pdf" target="_blank"
                                    href="/assets/answersheet/JRBT Notification in Bengali.pdf" class="newContent">
                                    JRBT Notification in Bengali
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="JRBT Notification in English.pdf" target="_blank"
                                    href="/assets/answersheet/JRBT Notification in English.pdf" class="newContent">
                                    JRBT Notification in English
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="How to Fill-Up OMR Answer Sheet in JRBT written exam.pdf" target="_blank"
                                    href="/assets/How to Fill-Up OMR Answer Sheet in JRBT written exam.pdf" class="newContent">
                                    How to Fill-Up OMR Answer Sheet in JRBT written exam
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="Addendum for reference Advt.No.012020.pdf" target="_blank"
                                    href="/assets/Addendum for reference Advt.No.012020.pdf" class="newContent">
                                    Addendum for reference Advt.No.01/2020
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="Central Strong Room.pdf" target="_blank"
                                    href="/assets/Central Strong Room.pdf" class="newContent">
                                    Central Strong Room
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Information.pdf" target="_blank"
                                    href="/assets/Information.pdf" class="newContent">
                                    Information About Central Strong Room
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Instructions to the Candidates Bengali.pdf" target="_blank"
                                    href="/assets/Instructions to the Candidates Bengali.pdf" class="newContent">
                                    Instructions To The Candidates Of The Written Examination For Group C and Group D Under JRBT.  Bengali
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Instructions to the Candidates English.pdf" target="_blank"
                                    href="/assets/Instructions to the Candidates English.pdf" class="newContent">
                                    Instructions To The Candidates Of The Written Examination For Group C and Group D Under JRBT.  English
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="GroupC.pdf" target="_blank"
                                    href="/assets/Groupc.pdf" class="newContent">
                                    Attention! JRBT group C exam date out - English
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="GroupD.pdf" target="_blank"
                                    href="/assets/GroupD.pdf" class="newContent">
                                    Attention! JRBT group D exam date out - English
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="GroupC.jpeg" target="_blank"
                                    href="/assets/GroupC.jpeg" class="newContent">
                                    Attention! JRBT group C exam date out - Bengali
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="GroupD.jpeg" target="_blank"
                                    href="/assets/GroupD.jpeg" class="newContent">
                                    Attention! JRBT group D exam date out - Bengali
                                </a>
                            </li>

                            <li class="discloser">
                                <a download="ExamPospondBengali.pdf" target="_blank"
                                    href="/assets/ExamPospondBengali.pdf" class="newContent">
                                    URGENT- JRBT Exam PostPoned- Bengali
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="ExamPospondEnglish.pdf" target="_blank"
                                    href="/assets/ExamPospondEnglish.pdf" class="newContent">
                                    URGENT- JRBT Exam PostPoned - English
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="AttentionForApplicantsOutsideofTripura.pdf" target="_blank"
                                    href="/assets/AttentionForApplicantsOutsideofTripura.pdf" class="newContent">
                                    Attention For Applicants Out Side Of Tripura
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="AttentionpdfinBengali.pdf" target="_blank"
                                    href="/assets/AttentionpdfinBengali.pdf" class="newContent">
                                    Attention For Applicants Out Side Of Tripura (Bengali)
                                </a>
                            </li>
                            <li class="discloser">
                                <a (click)="ShowVideos()" class="newContent" style="cursor: pointer;font-size: 18px;">Video on how to fill up OMR answer sheet for written exam for Group-C and Multi Tasking Staff (Group-D) under JRBT</a>
                                <!-- <p data-toggle="modal" data-target="#myModal">Video on how to fill up OMR answer sheet for written exam for Group-C and Multi Tasking Staff (Group-D) under JRBT</p> -->
                                <div class="popupbody" *ngIf="isShowVideos">
                                    <div class="popupcontent">
                                        <div class="d-flex">
                                            <i class="fa fa-times" aria-hidden="true" (click)="isShowVideos = false"></i>
                                        </div>
                                        <div class="Video">
                                            <video width="100%" height="auto" controls autoplay>
                                                <source src="../../assets/FillApplicationViedo.mp4" type="video/mp4">
                                              </video>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="discloser">
                                <a download="Notification For Candidates.pdf" target="_blank"
                                    href="/assets/instructiontothecandidates.pdf" class="newContent">
                                    NOTIFICATION FOR CANDIDATES
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Extension Of Last Date For Downloading.pdf" target="_blank"
                                    href="/assets/Document72.pdf" class="newContent">
                                    Extension Of Last Date For Downloading Admit Card For Gp-C & Gp-D Under JRBT
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="MEMORANDUMOFGROUPCANDGROUPDE-PROVISIONALADMISSIONCERTIFICATE.pdf"
                                    target="_blank" href="/assets/OfficeLens.pdf" class="newContent">
                                    MEMORANDUM OF GROUP C AND GROUP D E-PROVISIONAL ADMISSION CERTIFICATE
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Group C Notification.pdf" target="_blank"
                                    href="/assets/ExamNotificationofGrpC.pdf" class="newContent">
                                    EXAM NOTIFICATION OF GROUP- C
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Group D Notification.pdf" target="_blank"
                                    href="/assets/ExamNotificationofGrpD.pdf" class="newContent">
                                    EXAM NOTIFICATION OF GROUP- D
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (English).pdf" class="newContent">
                                    Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (English)
                                    
                                </a>
                            </li>

                            <li class="discloser" >
                                <a download="Notice English for MTS (Gr.-D).pdf" target="_blank"
                                    href="/assets/Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (Bengali).pdf" class="newContent">
                                    Notice regarding extension of date for filling up of online application form of MTS(Gr.-D) posts (Bengali)
                                    
                                </a>
                            </li>
                            <!-- 20-02-24 -->

                            <li class="discloser" >
                                <a download="summaryofwrittenexaminationforrecruitmentofGrCposts.pdf" target="_blank"
                                    href="/assets/Notice for Group-C & MTS (Gr-D) posts.pdf" class="newContent">
                                    Notice for Group-C & MTS (Gr-D) posts
                                </a>
                            </li>
                            <li class="discloser" >
                                <a download="summaryofwrittenexaminationforrecruitmentofGrCposts.pdf" target="_blank"
                                    href="/assets/last date.pdf" class="newContent">
                                    Notice for Group-C & MTS (Gr-D) posts
                                </a>
                            </li>
                            <!-- 29-05-24 -->
                            <li class="discloser" >
                                <a download="Corrigendum, dated 19th December 2020.pdf" target="_blank"
                                    href="/assets/Corrigendum, dated 19th December 2020.pdf" class="newContent">
                                    Corrigendum
                                </a>
                            </li>
                            <!-- 29-05-24 -->

                            <!-- 20-02-24 End -->
                            <li class="discloser">
                                <a download="Group C Notification.pdf" target="_blank"
                                    href="/assets/SyllabusForGroup-C.pdf" class="newContent">
                                    SYLLABUS FOR GROUP-C COMMON WRITTEN EXAMINATION BY JRBT
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Group D Syllabus.pdf" target="_blank" href="/assets/SyllabusforGroup-D.pdf"
                                    class="newContent">
                                    SYLLABUS FOR GROUP-D WRITTEN EXAMINATION BY JRBT
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Addenudum For Group C Exam Syllabus.pdf" target="_blank"
                                    href="/assets/ADDENDUMFORGROUP-CEXAMSYLLABUS.pdf" class="newContent">
                                    ADDENDUM FOR GROUP-C EXAM SYLLABUS
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Scheme Of Exam Of Group C.pdf" target="_blank"
                                    href="/assets/SCHEMEOFEXAMFORGROUP-C.pdf" class="newContent">
                                    SCHEME OF EXAM OF GROUP C
                                </a>
                            </li>
                            <li class="discloser">
                                <a download="Scheme Of Exam Of Group D.pdf" target="_blank"
                                    href="/assets/SchemeofExamGroup-D.pdf" class="newContent">
                                    SCHEME OF EXAM OF GROUP D
                                </a>
                            </li>

                        </ul>
                    </div>
                    <ul>
                        <div class="waviy">
                            <span style="--i:1">R</span>
                            <span style="--i:2">U</span>
                            <span style="--i:3">L</span>
                            <span style="--i:4">E</span>
                            <span style="--i:5">S</span>
                            <span style="--i:6">.</span>
                            <span style="--i:7">.</span>
                            <span style="--i:8">.</span>
                        </div>
                        <li>The syllabus for Group-C & Group-D examination by JRBT has been uploaded in Directorate of
                            Employment Services & Manpower planning website <a href="https://employment.tripura.gov.in/"
                                target="blank">https://employment.tripura.gov.in/</a> </li>
                        <li>The last date of filling up of online application for 2410 Group C post (1500 LDC, 450Agri.
                            Assistant (TAFS Grade-III and Except TAFS Grade-III), 236 Junior Operator (Pump) and 209
                            Junior Multi Tasking Operator (Un-common) is on 12th March,2021</li>
                        <li>১৫০০ এল ডি সি, ৪৬৫ এগ্রি অ্যাসিস্ট্যান্ট, ২০৯ জুনিয়র মাল্টি টাস্কিং ক্লার্ক এবং ২৩৬ জুনিয়র
                            অপারেটর (পাম্প) (গ্রুপ – সি) পদে অনলাইন আবেদনের শেষ তারিখ ১২ই মার্চ ২০২১।</li>
                        <li>As it is a common application, those candidates who have already applied for 1500 posts of
                            LDC need not apply for other Group-C posts and their candidature will be considered for all
                            2410 GroupC posts based on their Educational Qualification</li>
                        <li>যেহেতু এটি কমন বিজ্ঞাপন, তাই যারা ১৫০০ এল ডি সি পদে আবেদন করেছে তাদের আর অন্যান্য গ্রুপ – সি
                            পদে আবেদন করতে হবে না । শিক্ষাগত যোগ্যতার ভিত্তিতে ২৪১০ গ্রুপ – সি পদে বিবেচিত হবে।</li>
                        <li>Intending candidates should register first in order to fill-up online application for
                            various posts notified by JRBT.</li>
                        <li>Before filling up the online application form the candidates should go through detailed
                            advertisement, corrigendum or Addendum issued by JRBT in <a
                                href=" http://employment.tripura.gov.in"
                                target="_blank">http://employment.tripura.gov.in</a></li>
                        <li>Contact 0381-2326782 for assistance between 10:30 am to 5:00 pm on all working days.</li>
                        <li>For any query or help, you may write us to jrbtripura.rect2020@gmail.com. Please mention
                            your name and registered mobile no while writing to us.</li>

                    </ul>

                </div>
                <div class="col-md-4 Login_form h-100">
                    <div class="form">

                        <h6 *ngIf="isDepartmentLogin">Department Level Login</h6>
                        <h6 *ngIf="!isDepartmentLogin">User Login</h6>
                        <hr>
                        <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
                            <div class="form-group">
                                <label>Email Id / Phone Number <span style="color:#ff0000">*</span></label>
                                <input type="text" formControlName="email" placeholder="Email ID or Phone Number"
                                    maxlength="100" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email ID or registration number required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Password <span style="color:#ff0000">*</span></label>
                                <input type="password" formControlName="password" placeholder="Type Your Password"
                                    maxlength="20" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters
                                    </div>
                                </div>
                            </div>
                            <div class="captcha">
                                <div class="form-group">
                                    <label for="pwd">Verification Code <span style="color:#ff0000">*</span></label>
                                    <div class="fr_flx">
                                        <input type="text" class="form-control captha_txt"
                                            placeholder="Enter Verification Code" formControlName="captcha"
                                            [ngClass]="{ 'is-invalid': submitted && f.captcha.errors }" />
                                        <i class="fa fa-refresh" (click)="GenerateCaptcha()" aria-hidden="true"></i>
                                        <div class="captchaValueDisplay noselect" onmousedown='return false;'
                                            onselectstart='return false;'>{{captchaValue}}</div>
                                    </div>
                                    <div *ngIf="submitted && f.captcha.errors" class="invalid-feedback">
                                        <div *ngIf="f.captcha.errors.required">Verification Code is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" style="display: flex;">
                                <!-- <p class="frgt_pass">Forgot Password</p> -->
                                <button class="btn btn-primary lgnbtn" type="submit">Log In</button>
                                <div class="flex_grow"></div>
                                <button class="btn btn-primary " routerLink='/forgetpassword'>Forgot Password</button>
                            </div>
                        </form>
                        <hr>
                        <p class="nw_reg" routerLink='/register'>New User Registration</p>

                        <hr>
                        <div class="form-group">
                            <!-- <button class="btn btn-primary dept_lgn" (click)="changeLoginType(); $event.preventDefault()">
                                <label *ngIf="!isDepartmentLogin">Department Login</label>
                                <label *ngIf="isDepartmentLogin">User Login</label>
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- =========body section end========= -->
<!-- <div class="container">
    <div class="panel-group">
        <div class="panel panel-default">
            <div class="panel-heading">
                <label *ngIf="isDepartmentLogin">Department Level Login</label>
                <label *ngIf="!isDepartmentLogin">User Login</label>

                </div>
            <div class="panel-body">
                <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
                    <div class="form-group">
                        <label>User ID:</label>
                        <input type="text" formControlName="email" placeholder="Email/MobileNumber" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email ID or registration number required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" formControlName="password" placeholder="Enter Password"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary" (ngSubmit)="loginSubmit()">Login</button>
                    </div>
                    <div class="form-group">
                        <button (click)="changeLoginType(); $event.preventDefault()">
                            <label *ngIf="!isDepartmentLogin">Department Login</label>
                            <label *ngIf="isDepartmentLogin">User Login</label>
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div> -->